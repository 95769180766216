import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function VideoGallery(props) {

    const videos = props.videos

    return (
        <Container>
          <Row lg={3} xs={1}>
            {videos.map(video => {
              return (
                <Col>
                  <div key={video.id}>
                    <div class="embed-responsive">
                      <iframe
                        class="embed-responsive-item"
                        title={video.name}
                        src={video.linkToVideo}
                        allowfullscreen
                        >
                        </iframe>
                    </div>
                    <p>{video.name}</p>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
    )

}