// extracted by mini-css-extract-plugin
export var banner_image = "event-information-module--banner_image--e4b8c";
export var event_description_container = "event-information-module--event_description_container--ddeb9";
export var h1_performers = "event-information-module--h1_performers--5b94f";
export var job_title = "event-information-module--job_title--c1688";
export var performer_photo = "event-information-module--performer_photo--0bdf4";
export var speaker = "event-information-module--speaker--2d68b";
export var speaker_bio = "event-information-module--speaker_bio--45420";
export var speaker_info = "event-information-module--speaker_info--fa625";
export var speaker_photo = "event-information-module--speaker_photo--f5f8f";
export var speakers_container = "event-information-module--speakers_container--5e4ef";
export var tedxki_container = "event-information-module--tedxki_container--d7292";